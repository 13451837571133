<template>
    <div class="container">
        <div class="create-title">
            <div v-if="editForm.id">编辑优惠券</div>
            <div v-else>新增优惠券</div>
            <div class="btn">
                <el-button @click="routeChange(1)">取消</el-button>
                <el-button type="primary" @click="handleConfirm">保存</el-button>
            </div>
        </div>
        <div class="create-container">
            <colorful-title title="基本信息" class="pd-custom" />
            <div class="form-item">
                <div class="key">优惠券名称</div>
                <el-input class="value" v-model="editForm.name" placeholder="请输入优惠券名称"></el-input>
            </div>
            <div class="form-item">
                <div class="key">优惠券类型</div>
                <div class="value">
                    <el-radio :label="1" v-model="editForm.type" @change="handleChange">满减优惠券</el-radio>
                    <el-radio :label="2" v-model="editForm.type" @change="handleChange">打折优惠券</el-radio>
                </div>
            </div>
            <div class="form-item" v-if="editForm.type === 1">
                <div class="key">优惠金额</div>
                <el-input-number class="value" v-model="editForm.money" controls-position="right" :min="0"
                    :precision='2' placeholder="请输入优惠金额" />
            </div>
            <div class="form-item" v-if="editForm.type === 2">
                <div class="key">优惠折扣</div>
                <el-slider class="value" :format-tooltip="tooltipChange" v-model="editForm.discount" :min="1" :max="99">
                </el-slider>
            </div>
            <div class="form-item">
                <div class="key">使用门槛</div>
                <div class="value">
                    <el-radio :label="1" v-model="editForm.use" @change="useChange">无使用门槛</el-radio>
                    <el-radio :label="2" v-model="editForm.use" @change="useChange">
                        满
                        <el-input-number v-model="editForm.price" controls-position="right" :min="0" :precision='2'
                            style="width: 160px;margin: 0 10px;" :disabled="useDisabled" placeholder="输入金额" />元使用
                    </el-radio>
                </div>
            </div>
            <div class="form-item">
                <div class="key">发行量</div>
                <el-input class="value" v-model.number="editForm.count" placeholder="请输入发行量"></el-input>
            </div>
            <div class="form-item">
                <div class="key">适用商品</div>
                <div class="value row">
                    <el-radio :label="0" v-model="editForm.apply" @change="applyChange">全部商品可用</el-radio>
                    <el-radio :label="1" v-model="editForm.apply" @change="applyChange">指定商品可用</el-radio>
                    <select-course content='新增商品' v-if="isApplyShow" :showColumn="true" class="add-field"
                        dialogTitle='添加商品' @submit="handleCourseSubmit" :items='commodityList'>
                    </select-course>
                </div>

            </div>
            <div class="column">
                <div v-for="(item, index) of commodityList" :key="index" class="commodity">
                    <div class="course-cover">
                        <el-image :src="IMG_URL_PREFIX + item.cover" fit='cover' class="course-cover-img"
                            v-if="item.cover">
                            <div slot="error" class="error iconfont icon-p-image"></div>
                        </el-image>
                        <div class="error iconfont icon-p-image" v-else></div>
                    </div>
                    <div class="column-1">
                        <div class="name">{{ item.name }}</div>
                        <div class="price">¥{{ item.price / 100}}</div>
                        <div class="type">商品类型：{{ item.media_type | courseTypeFilter }}</div>
                    </div>
                    <img src="@/assets/images/delete.png" alt="" class="delete" @click="handleDelete(index)">
                </div>
            </div>
            <div class="form-item" style="margin-top: 38px">
                <div class="key">使用时间</div>
                <el-date-picker class="value" v-model="editForm.validity" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <div class="form-item">
                <div class="key">选择用户</div>
                <div class="value">
                    <el-radio :label="0" v-model="editForm.select_user" @change="selectChange">全部用户</el-radio>
                    <el-radio :label="1" v-model="editForm.select_user" @change="selectChange">指定用户群组</el-radio>
                    <el-button type="primary" icon="el-icon-plus" v-if="showUserBtn" @click="showDialog">添加指定用户群
                    </el-button>
                </div>
            </div>
            <div class="form-item" v-if="isShowUser">
                <div class="key"></div>
                <div class="value">
                    <el-table :data="userList" :header-cell-style="{height: '40px', background: 'rgba(246,248,250,1)'}">
                        <el-table-column label="用户名" prop="user_name">
                            <template slot-scope="scope">
                                {{ scope.row.name || scope.row.username || scope.row.user_name}}
                            </template>
                        </el-table-column>
                        <el-table-column label="手机号码" prop="phone"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" class="icon-btn" @click="handleUserDelete(scope.$index)">
                                    <el-tooltip placement="top" content="删除">
                                        <i class="iconfont icon-delete" />
                                    </el-tooltip>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <colorful-title title="领取规则" class="pd-custom" />
            <div class="form-item">
                <div class="key">领取次数限制</div>
                <div class="value">
                    <el-radio :label="1" v-model="editForm.num_limit" @change="limitChange">不限次数</el-radio>
                    <el-radio :label="2" v-model="editForm.num_limit" @change="limitChange">每人限领<el-input
                            v-model.number="editForm.limit" :disabled="limitDis" style="width: 160px;padding: 0 10px;"
                            placeholder="输入次数">
                        </el-input>次</el-radio>
                </div>
            </div>
            <div class="form-item mb-20">
                <div class="key">活动叠加限制</div>
                <el-checkbox class="value" v-model="editForm.overlay">优惠券仅原价购买商品时可用，不可与其他营销活动叠加使用
                </el-checkbox>
            </div>
        </div>
        <el-dialog title="添加适用用户" :visible.sync="dialogVisible" width="600px" :closed="handleDialogClose">
            <div class="dialog-content">
                <div class="form-item">
                    <!-- <div class="form-item-name">联系方式</div>
                <div class="form-item-content">
                    <el-input class="value" v-model.number="dialogForm.phone" placeholder="请输入联系方式"></el-input>
                </div> -->
                    <div class="key">适用用户</div>
                    <el-select class="val" v-model="dialogForm.phone" filterable clearable
                        placeholder="请选择用户(可通过手机号筛选)">
                        <el-option v-for="item of allUserList" :key="item.id" :label="item.phone" :value="item.phone">
                            <span style="float: left">{{ item.phone }}</span>
                            <span
                                style="float: right; color: #8492a6; font-size: 13px;margin-left: 10px">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button type="primary" @click="handleSave">保存</el-button>
                <el-button @click="handleDialogClose">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';
import axios from 'axios';
import { baseUrl } from '@/core/api/config';
axios.defaults.headers.post['Content-Type'] = 'application/json';
export default {
    components: {
        ColorfulTitle: () => import('@/components/common/ColorfulTitle'),
        SelectCourse: () => import('@/components/online-edu/SelectCourse')
    },
    data() {
        return {
            IMG_URL_PREFIX: Core.Const.NET.FILE_URL,
            editForm: {
                id: '',
                name: '',
                type: 1,
                money: undefined,
                discount: 0,
                use: 1,
                price: undefined,
                count: '',
                apply: 0,
                validity: [],

                select_user: 0,
                limit: '',
                num_limit: 1,
                overlay: ''
            },
            dialogForm: {
                id: 0,
                phone: ''
            },
            detail: '',
            full: false,
            discount: false,
            showUserBtn: false,
            dialogVisible: false,
            commodityList: [],
            isApplyShow: false,
            userList: [],
            isShowUser: false,
            useDisabled: true,
            limitDis: true,

            allUserList: []
        };
    },
    watch: {},
    created() {
        this.editForm.id = this.$route.query.id;
    },
    computed: {},
    mounted() {
        if (this.editForm.id) {
            console.log('getDetail');
            this.getDetail();
        }
        this.getAllUserList();
    },
    methods: {
        getDetail() {
            Core.Api.Coupon.detail(this.editForm.id).then((res) => {
                console.log('getDetail res:', res);
                this.detail = res.detail;
                this.editForm.type = res.detail.type;

                this.editForm.name = res.detail.name;
                this.editForm.price = res.detail.price / 100;
                this.editForm.money = res.detail.money / 100;

                if (res.detail.price != 0) {
                    this.editForm.use = 2;
                    this.useDisabled = false;
                } else {
                    this.editForm.use = 1;
                    this.useDisabled = true;
                }
                this.editForm.discount = res.detail.discount;
                this.editForm.validity = [
                    res.detail.begin_time * 1000,
                    res.detail.end_time * 1000
                ];
                this.editForm.overlay = res.detail.flag_open_share
                    ? true
                    : false;
                if (res.detail.user_get_count == 0) {
                    this.editForm.num_limit = 1;
                } else {
                    this.editForm.limit = res.detail.user_get_count;
                    this.editForm.num_limit = 2;
                    this.limitDis = false;
                }
                this.editForm.count = res.detail.send_count;
                this.editForm.select_user = res.detail.user_range;
                this.editForm.apply = res.detail.use_range;
                if (res.detail.user_range == 1) {
                    this.getUserList(this.editForm.id);
                    this.isShowUser = true;
                    this.showUserBtn = true;
                }
                if (res.detail.use_range == 1) {
                    this.getCourseList(this.editForm.id);
                    this.isApplyShow = true;
                }
            });
        },
        getUserList(id) {
            Core.Api.Coupon.whiteList(id, 0).then((res) => {
                this.userList = res.list;
            });
        },
        getAllUserList() {
            Core.Api.User.allUserList().then((res) => {
                console.log('getUserListAll', res);
                this.allUserList = res.list;
            });
        },
        getCourseList(id) {
            Core.Api.Coupon.useGoods(id, 0, 10).then((res) => {
                console.log('getCourseList', res);
                this.commodityList = res.list.map((item) => item.course);
            });
        },
        tooltipChange(o) {
            return o / 10 + '折';
        },
        handleChange(o) {
            if (o == 1) {
                this.editForm.discount = 0;
            } else {
                this.editForm.money = '';
            }
        },
        selectChange(o) {
            if (o == 1) {
                this.showUserBtn = true;
                this.isShowUser = true;
                return;
            }
            this.showUserBtn = false;
            this.isShowUser = false;
        },
        applyChange(o) {
            if (o == 1) {
                this.isApplyShow = true;
                return;
            }
            this.commodityList = [];
            this.isApplyShow = false;
        },
        useChange(o) {
            if (o == 1) {
                this.editForm.price = '';
                this.useDisabled = true;
                return;
            }
            this.useDisabled = false;
        },
        limitChange(o) {
            if (o == 1) {
                this.editForm.limit = '';
                this.limitDis = true;
                return;
            }
            this.limitDis = false;
        },
        routeChange(type) {
            switch (type) {
                case 1:
                    this.$router.go(-1);
                    break;
            }
        },
        async getCommodityList(ids) {
            if (ids === undefined) return;
            let idsArray = ids.split(',');
            let commodityList = [];
            for (let i of idsArray) {
                await Core.Api.Course.detail(i).then((res) => {
                    commodityList.push(res.detail);
                });
            }
            this.commodityList = commodityList;
            console.log('this.commodityList', this.commodityList);
        },
        handleCourseSubmit({ ids, items }) {
            if (ids.length > 100) {
                return this.$message.warning('最多添加100个商品');
            }
            console.log('handleCourseSubmit ids:', ids);
            this.commodityList = items;
        },
        handleDelete(index) {
            this.commodityList.splice(index, 1);
        },
        handleUserDelete(index) {
            this.userList.splice(index, 1);
        },
        showDialog() {
            this.dialogVisible = true;
            this.dialogForm.phone = '';
        },
        handleDialogClose() {
            this.dialogVisible = false;
        },
        handleSave() {
            if (!this.dialogForm.phone) {
                return this.$message.warning('请选择适用用户');
            }
            Core.Api.User.detailByPhone(this.dialogForm.phone).then((res) => {
                console.log(`detailByPhone res`, res);
                this.$message.success('保存成功');
                this.userList.push(res.detail);
                this.isShowUser = true;
                this.dialogVisible = false;
            });
        },

        handleConfirm() {
            console.log('editForm:', this.editForm);
            const phones = this.userList.map((item) => item.phone);
            const target_ids = this.commodityList.map((item) => item.id);
            const config = {
                id: this.editForm.id || 0,
                name: this.editForm.name,
                use_type: 10,
                type: this.editForm.type,
                price: this.editForm.price * 100 || 0,
                money: this.editForm.money * 100,
                discount: this.editForm.discount,
                begin_time: this.editForm.validity[0] / 1000,
                end_time: this.editForm.validity[1] / 1000,
                send_count: this.editForm.count,
                user_get_count: this.editForm.limit || 0,
                use_range: this.editForm.apply,
                user_range: this.editForm.select_user,
                phones: phones.join(','),
                target_ids: target_ids.join(','),
                flag_open_share: this.editForm.overlay ? 1 : 0
            };
            if (!this.editForm.name) {
                return this.$message.warning('请输入优惠券名称');
            }
            if (!this.editForm.type) {
                return this.$message.warning('请选择优惠券类型');
            }
            if (this.editForm.type == 1 && this.editForm.money === '') {
                return this.$message.warning('请输入优惠金额');
            }
            if (!this.editForm.use) {
                return this.$message.warning('请选择使用门槛');
            }
            if (!this.editForm.count) {
                return this.$message.warning('请输入发行量');
            }
            if (!config.begin_time) {
                return this.$message.warning('请选择使用时间');
            }
            if (!this.editForm.num_limit) {
                return this.$message.warning('请选择领取次数限制');
            }
            const data = JSON.stringify(config);
            axios
                .post(
                    baseUrl +
                        '/provider/1/coupon/save' +
                        '?token=' +
                        Core.Data.getToken(),
                    data
                )
                .then((res) => {
                    console.log('handleSave =>', res);
                    this.$message.success('保存成功');
                    this.routeChange(1);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    padding: 0 30px 40px;
    .create-title {
        height: 62px;
        line-height: 62px;
        font-size: 20px;
        font-weight: 500;
        color: #323538;
        display: flex;
        justify-content: space-between;
    }
    .create-container {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e1e7ec;
        overflow: hidden;
        .pd-custom {
            padding: 30px 60px;
        }
        .form-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            min-height: 32px;
            box-sizing: border-box;
            padding: 0 70px;
            + .form-item {
                margin-top: 38px;
            }

            .key {
                width: 100px;
            }
            .value {
                width: 500px;
                margin-left: 16px;
                display: flex;
                align-items: center;
            }
            &.mb-20 {
                margin-bottom: 50px;
            }
            .add-field {
                border: none;
                height: 0;
                margin: 0;
                margin-top: -35px;
            }
        }
        .column {
            display: flex;
            flex-direction: column;
            width: 500px;
            padding: 0 0 0 184px;
            .commodity {
                height: 90px;
                background: #f7f9fa;
                border-radius: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
                // margin-bottom: 20px;
                padding-left: 20px;
                margin-top: 20px;
                position: relative;
                .course-cover {
                    width: 80px;
                    height: 60px;
                    background: #dee5ea;
                    border-radius: 1px;
                    overflow: hidden;
                    margin-right: 20px;
                    text-align: center;
                    line-height: 60px;
                    .course-cover-img {
                        width: 100%;
                        height: 100%;
                    }
                    .error {
                        font-size: 14px;
                    }
                }
                .delete {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    right: -6px;
                    top: -6px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .column-1 {
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-size: 14px;
                        font-weight: 400;
                        color: #363d42;
                        margin-bottom: 6px;
                        margin-right: 10px;
                    }
                    .price {
                        font-size: 10px;
                        font-weight: 400;
                        color: #1D517E;
                        margin-bottom: 6px;
                    }
                    .type {
                        font-size: 12px;
                        font-weight: 400;
                        color: #b2b2b2;
                    }
                }
            }
        }
    }
    .dialog-content {
        width: 100%;
        .form-item {
            @include flex(row, flex-start, center);
            .key {
                width: 100px;
                font-size: 14px;
                color: #8090a6;
            }
            .val {
                width: calc(100% - 100px);
            }
        }
    }
}
</style>
